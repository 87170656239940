import React from "react";
import "./Projects.scss";
import Heading from "../../components/Headings/Heading";

const Support = () => {
  document.title = 'SMPeoples | Location';

  return (
    <div className='pt-16'>
      <br/>
      <br/>
      <br/>
      <Heading heading="Support"></Heading>
      <div className='my-20 flex flex-wrap gap-10 items-center justify-evenly'>
      </div>
    </div>
  )
}

export default Support;
