import Heading1 from '../Headings/Heading1'
import Heading5 from '../Headings/Heading5'
import { Card4 } from '../Cards/Card'
import { RxArrowRight } from 'react-icons/rx'
import { Link } from "react-router-dom";
import React from "react";

const Hmain= () => {

  return (
    <div>
      <Heading5 text1={"보안"} text2={"뉴스"} />
      <div className='my-20 flex flex-wrap gap-10 items-center justify-evenly'>
        <Card4 link="https://www.boho.or.kr/kr/bbs/view.do?searchCnd=&bbsId=B0000133&searchWrd=&menuNo=205020&pageIndex=1&categoryCode=&nttId=71167" title="MS 8월 보안 위협에 따른 정기 보안 업데이트 권고" company={"KISA"} image="./images/kisa.png" />
        <Card4 link="https://www.boannews.com/media/view.asp?idx=120762" title="시트릭스 코드 주입 취약점" company={"보안뉴스"} image="http://www.boannews.com/media/upFiles2/2023/08/217829519_9931.jpg" />
        <Card4 link="https://www.boannews.com/media/view.asp?idx=120727" title="부채널 공격 기법 발견돼" company={"보안뉴스"} image="http://www.boannews.com/media/upFiles2/2023/08/216902506_3247.jpg" />
        
      </div>
      <Link data-aos="zoom-in" style={{ textDecoration: "none" }} to="/News" className='bg-sky-600 absolute lg:right-32 md:right-16 right-10 hover:bg-sky-500 text-white visited:text-white font-semi-bold py-2.5 px-5 rounded-full flex items-center -mt-10'>
                <button className="mr-2 text-white text-sm">
                    View All
                </button>
                <RxArrowRight size={18} />
            </Link>
      <br/>
      <br/>
      <Heading1 details={" "} text1={"회사"} text2={"소개서"} />
      <div className='my-20 flex flex-wrap gap-10 items-center justify-evenly'>
            <Link data-aos="zoom-in" style={{ textDecoration: "none" }} to="./logo.png" target="_blank"className='bg-sky-600  lg:right-32 md:right-16 hover:bg-sky-500 text-white visited:text-white font-semi-bold py-2.5 px-5 '
               download>
                <button className="mr-2 text-white text-sm">
                Download
               </button>
               </Link>
                </div>
          </div>
  )
}

export default Hmain