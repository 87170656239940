// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.large-heading {
  border-left: 5px solid rgb(255, 114, 138);
  padding: 0 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Headings/Heading.scss"],"names":[],"mappings":"AAAA;EACG,yCAAA;EACA,eAAA;AACH","sourcesContent":[".large-heading{\n   border-left: 5px solid rgb(255, 114, 138);\n   padding: 0 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
