import React from 'react'
import  './Events.scss'
import Heading3 from '../../../components/Headings/Heading3'

const Nile = () => {

  document.title = 'SMPeoples | 나일소프트';

  return (
    <div className='pt-16 min-h-screen'>
      <br/>
      <br/>
      <br/>
      <Heading3 heading="나일소프트"></Heading3>
      <div className="paragraph-body">
      <center>
      <img src = '/images/제품소개서/시큐위즈/시큐위즈1.jpg' style={{ width: "85%", height: "40%" }} alt="1"/>
      <br/>
      <img src = '/images/제품소개서/시큐위즈/시큐위즈2.jpg' style={{ width: "85%", height: "40%" }} alt="2"/>
      <br/>
      <img src = '/images/제품소개서/시큐위즈/시큐위즈3.jpg' style={{ width: "85%", height: "40%" }} alt="3"/>
      <br/>
      <img src = '/images/제품소개서/시큐위즈/시큐위즈4.jpg' style={{ width: "85%", height: "40%" }} alt="4"/>
      <br/>
      <img src = '/images/제품소개서/시큐위즈/시큐위즈5.jpg' style={{ width: "85%", height: "40%" }} alt="5"/>
      <br/>
      <img src = '/images/제품소개서/시큐위즈/시큐위즈6.jpg' style={{ width: "85%", height: "40%" }} alt="6"/>
      <br/>
      </center>

      </div>
    </div>
  )
}

export default Nile