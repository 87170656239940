import React from 'react';
import { Map, MapMarker } from "react-kakao-maps-sdk";



        const KaKao_A = () => {
            return (
              <Map
                center={{ lat: 36.360595953142294, lng: 127.34345351275964 }}
                style={{ width: "80%", height: "400px" }}
              >
                <MapMarker
                  position={{ lat: 36.360595953142294, lng: 127.34345351275964 }}
                >
                </MapMarker>
              </Map>
            );
          };

export default KaKao_A;