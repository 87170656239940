import './About.scss'
import Heading from '../../components/Headings/Heading'

const About = () => {

  document.title = 'SMPeoples | 소개';

  return (
    <div className='pt-16 min-h-screen'>
      <br/>
      <br/>
      <br/>
      <Heading heading="회사소개"></Heading>
      <div className="paragraph-body">
        <p data-aos="fade-up" >
          안녕하세요 <span>에스엠피플스㈜</span>입니다.
          현재 홈페이지 재단장 중입니다.
        </p>

        <p data-aos="fade-up" >
           <span>감사합니다.</span> 
        </p>
      </div>
    </div>
  )
}

export default About
