// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 100vh;
}

.error_img {
  width: 30%;
  height: auto;
  margin: 0;
}

.error_head {
  font-size: 4em;
  font-weight: 800;
  margin: 0;
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.error_line {
  font-size: 1.5em;
  margin: 0;
  font-weight: 700;
}

.error_btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: rgb(58, 130, 246);
  margin-top: 30px;
  font-size: 1.2rem;
  text-decoration: none;
  color: white;
  border-radius: 50px;
  animation: bounce 1s ease-in-out infinite;
}

a:-webkit-any-link {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

@keyframes bounce {
  0%, 20%, 30%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Error/404.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AADJ;;AAIA;EACI,UAAA;EACA,YAAA;EACA,SAAA;AADJ;;AAIA;EACI,cAAA;EACA,gBAAA;EACA,SAAA;EACA,wCAAA;AADJ;;AAGA;EACI;IACI,mBAAA;EAAN;EAEI;IACE,qBAAA;EAAN;EAEE;IACI,mBAAA;EAAN;AACF;AAGA;EACI,gBAAA;EACA,SAAA;EACA,gBAAA;AADJ;;AAKA;EACI,qBAAA;EACA,kBAAA;EACA,mCAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,YAAA;EACA,mBAAA;EACA,yCAAA;AAFJ;;AAKA;EACI,YAAA;EACA,eAAA;EACA,qBAAA;AAFJ;;AAIA;EACI;IACI,wBAAA;EADN;EAGC;IACK,4BAAA;EADN;EAGE;IACI,2BAAA;EADN;AACF","sourcesContent":["\n\n.error_container{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-color: black;\n    height: 100vh;\n    \n}\n.error_img{\n    width: 30%;\n    height: auto;\n    margin: 0;\n    \n}\n.error_head{\n    font-size: 4em;\n    font-weight: 800;\n    margin: 0;\n    animation:pulse 2s ease-in-out infinite;\n}\n@keyframes pulse {\n    0%{\n        transform: scale(1);\n    \n    } 50%{\n        transform: scale(1.2);\n    }\n    100%{\n        transform: scale(1);\n    }\n    \n}\n.error_line{\n    font-size: 1.5em;\n    margin: 0;\n    font-weight: 700;\n    \n}\n\n.error_btn{\n    display: inline-block;\n    padding: 10px 20px;\n    background-color:rgb(58 130 246 ) ;\n    margin-top: 30px;\n    font-size: 1.2rem;\n    text-decoration: none;\n    color: white;\n    border-radius: 50px;\n    animation:bounce 1s ease-in-out infinite;\n    \n}\na:-webkit-any-link {\n    color: white;\n    cursor: pointer;\n    text-decoration: none;\n}\n@keyframes bounce {\n    0% ,20%,30%,80%,100%{\n        transform: translateY(0);\n    }\n   40% {\n        transform:translateY(-10px)\n    }\n    60%{\n        transform: translateY(-5px);\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
