import React from 'react';
import { Map, MapMarker } from "react-kakao-maps-sdk";



        const KaKao_C = () => {
            return (
        
              <Map
                center={{ lat: 37.57787320936661, lng: 126.86238524970182 }}
                style={{ width: "80%", height: "400px" }}
              >
                <MapMarker
                  position={{ lat: 37.57787320936661, lng: 126.86238524970182 }}
                >
                </MapMarker>
              </Map>
            );
          };

export default KaKao_C;