import React from 'react'

const Heading1 = ({ text1, text2, details }) => {
    return (
        <div data-aos="fade-right">
            <h1  style={{ marginLeft: '20px' }} className='font-bold lg:text-4xl md:text-3xl text-2xl  mb-0'>{text1} <span className='text-sky-500'>{text2}</span></h1>
            <p style={{ marginLeft: '20px' }} className='font-bold text-sky-500 lg:text-base md:text-base text-sm  mt-5 max-w-x2'>
                { details ? details :""}
            </p>
        </div>
    )
}

export default Heading1