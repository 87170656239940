import React from 'react'
import './Events.scss'
import Heading from '../../components/Headings/Heading'
import Vendor from '../../components/HomePage/Vendor'


const Solutions = () => {

  document.title = 'SMPeoples | Solutions';


 
  return (
    <div className='pt-16'>
      <br/>
      <br/>
      <br/>
      <Heading heading="제품소개"></Heading>
      <div className='my-20 flex flex-wrap gap-10 items-center justify-evenly'>
      <div className='lg:mx-[4rem] md:mx-[3rem] mx-[2rem]'>
        <Vendor/>
      </div>
      </div>
    </div>
  )
}

export default Solutions