import Location from "./Location/Location";
import HomePage from "./HomePage/HomePage";
import Solutions from "./Solutions/Solutions";
import Support from "./Support/Support";
import News from "./Newsletter/News";
import About from "./About/About";
import Error from "./Error/404";
import Hsck from "./Solutions/Hsck/Hsck";
import Piolink from "./Solutions/Piolink/Piolink";
import Secuwiz from "./Solutions/Secuwiz/Secuwiz";
import Nile from "./Solutions/Nile/Nile";
import UTM from "./Solutions/UTM/UTM";
import Genians from "./Solutions/Genians/Genians";

export { Location, HomePage,Solutions, Support, News, About, Error, Hsck, Piolink, Secuwiz, Nile, UTM, Genians };