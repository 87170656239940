// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paragraph-body {
  max-width: 70%;
  text-align: justify;
  padding: 2rem 0;
  margin: 4rem auto;
}
.paragraph-body p {
  font-size: 1.1rem;
  line-height: 1.6rem;
  text-indent: 2rem;
  margin: 1rem 0;
  font-weight: 300;
}
.paragraph-body span {
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .paragraph-body {
    max-width: 80%;
    padding: 1.5rem 0;
  }
  .paragraph-body p {
    font-size: 1rem;
    line-height: 1.6rem;
    text-indent: 2rem;
    margin: 1rem 0;
  }
}
@media screen and (max-width: 1000px) {
  .paragraph-body {
    max-width: 90%;
    padding: 1rem 0;
  }
  .paragraph-body p {
    font-size: 1rem;
    line-height: 1.6rem;
    text-indent: 2rem;
    margin: 1rem 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/About/About.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AACF;AACE;EACE,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;;AAIA;EACE;IACE,cAAA;IACA,iBAAA;EADF;EAGE;IACE,eAAA;IACA,mBAAA;IACA,iBAAA;IACA,cAAA;EADJ;AACF;AAKA;EACE;IACE,cAAA;IACA,eAAA;EAHF;EAKE;IACE,eAAA;IACA,mBAAA;IACA,iBAAA;IACA,cAAA;EAHJ;AACF","sourcesContent":[".paragraph-body {\n  max-width: 70%;\n  text-align: justify;\n  padding: 2rem 0;\n  margin: 4rem auto;\n\n  p {\n    font-size: 1.1rem;\n    line-height: 1.6rem;\n    text-indent: 2rem;\n    margin: 1rem 0;\n    font-weight: 300;\n  }\n\n  span {\n    font-weight: 600;\n  }\n}\n\n@media screen and (max-width: 1000px) {\n  .paragraph-body {\n    max-width: 80%;\n    padding: 1.5rem 0;\n\n    p {\n      font-size: 1rem;\n      line-height: 1.6rem;\n      text-indent: 2rem;\n      margin: 1rem 0;\n    }\n  }\n}\n\n@media screen and (max-width: 1000px) {\n  .paragraph-body {\n    max-width: 90%;\n    padding: 1rem 0;\n\n    p {\n      font-size: 1rem;\n      line-height: 1.6rem;\n      text-indent: 2rem;\n      margin: 1rem 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
