import Heading1 from '../Headings/Heading1'
import { Card3 } from '../Cards/Card'
import React from "react";


const Vendor= () => {




  return (
    <div>
      <Heading1 details={" "} text1={"보안"} text2={"Solutions"} />
      <div className='my-20 flex flex-wrap gap-10 items-center justify-evenly'>
      <Card3 link="/images/제품소개서/한싹/SecureGateV3.5.pdf" title="망연계 솔루션" company={"한싹"} image="./images/제품소개서/한싹/hanssak0.png" />
      <Card3 link="/images/제품소개서/시큐위즈/시큐위즈-제품소개서.pdf" title="SSL VPN" company={"시큐위즈"} image="./images/제품소개서/시큐위즈/secuwiz0.png" />
      <Card3 link="/images/제품소개서/파이오링크/WAF.pdf" title="웹방화벽" company={"파이오링크"} image="./images/제품소개서/파이오링크/piolink0.png" />
      <Card3 link="/images/제품소개서/나일/WSE.pdf" title="웹 취약점진단" company={"나일소프트"} image="./images/제품소개서/나일/nile0.png" />
      <Card3 link="/images/제품소개서/나일/SSE.pdf" title="서버 취약점진단" company={"나일소프트"} image="./images/제품소개서/나일/nile0.png" />
      <Card3 link="/images/제품소개서/지니언스/NAC.pdf" title="NAC" company={"지니언스"} image="./images/제품소개서/지니언스/Genians0.png" />
      <Card3 link="/images/제품소개서/휴네시온/NGS.pdf" title="시스템 접근제어" company={"휴네시온"} image="./images/제품소개서/휴네시온/hu.png" />
      <Card3 link="/images/제품소개서/틸론/ti.pdf" title="데스크톱 가상화" company={"틸론"} image="./images/제품소개서/틸론/ti.png" />
      </div>
      </div>
  )
}

export default Vendor