import React from 'react'
import  './Events.scss'
import Heading3 from '../../../components/Headings/Heading3'


const Hsck = () => {

    document.title = 'SMPeoples | 한싹';
    return (
<div className='pt-16 min-h-screen'>
<br/>
<br/>
<br/>
<Heading3 heading="한싹"></Heading3>
<div className="paragraph-body">
<center>
<img src = '/images/제품소개서/한싹/한싹1.jpg' style={{ width: "85%", height: "40%" }} alt="1"/>
<br/>
<img src = '/images/제품소개서/한싹/한싹2.jpg' style={{ width: "85%", height: "40%" }} alt="2"/>
<br/>
<img src = '/images/제품소개서/한싹/한싹3.jpg' style={{ width: "85%", height: "40%" }} alt="3"/>
<br/>
<img src = '/images/제품소개서/한싹/한싹4.jpg' style={{ width: "85%", height: "40%" }} alt="4"/>
<br/>
<img src = '/images/제품소개서/한싹/한싹5.jpg' style={{ width: "85%", height: "40%" }} alt="5"/>
<br/>
<img src = '/images/제품소개서/한싹/한싹6.jpg' style={{ width: "85%", height: "40%" }} alt="6"/>
</center>

</div>
</div>
)
}
export default Hsck