import React from 'react'
import Heading from '../../components/Headings/Heading'
import { Card4 } from '../../components/Cards/Card'

const News = () => {
    document.title = 'SMPeoples | Location';

    return (
      <div className='pt-16'>
      <br/>
      <br/>
      <br/>
        <Heading heading="보안뉴스"></Heading>
        <div className='my-20 flex flex-wrap gap-10 items-center justify-evenly'>
        <Card4 link="https://www.boho.or.kr/kr/bbs/view.do?searchCnd=&bbsId=B0000133&searchWrd=&menuNo=205020&pageIndex=1&categoryCode=&nttId=71167" title="MS 8월 보안 위협에 따른 정기 보안 업데이트 권고" company={"KISA"} image="./images/kisa.png" />
        <Card4 link="https://www.boannews.com/media/view.asp?idx=120762" title="시트릭스 코드 주입 취약점" company={"보안뉴스"} image="http://www.boannews.com/media/upFiles2/2023/08/217829519_9931.jpg" />
        <Card4 link="https://www.boannews.com/media/view.asp?idx=120727" title="부채널 공격 기법 발견돼" company={"보안뉴스"} image="http://www.boannews.com/media/upFiles2/2023/08/216902506_3247.jpg" />
        <Card4 link="https://www.boho.or.kr/kr/bbs/view.do?searchCnd=&bbsId=B0000133&searchWrd=&menuNo=205020&pageIndex=1&categoryCode=&nttId=71160" title="지니언스 NAC 보안 업데이트 권고" company={"KISA"} image="https://www.genians.co.kr/hubfs/NAC%20%281%29-1.png" />
        <Card4 link="https://www.boho.or.kr/kr/bbs/view.do?searchCnd=&bbsId=B0000133&searchWrd=&menuNo=205020&pageIndex=1&categoryCode=&nttId=71129" title="Fortinet 보안 업데이트 권고" company={"KISA"} image="https://search.pstatic.net/common/?src=http%3A%2F%2Fimgnews.naver.net%2Fimage%2F5525%2F2018%2F10%2F11%2F0000070774_001_20181011223306771.jpg&type=sc960_832" />
      </div>
        </div>

    )
  }
export default News