// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Team_main_1 {
  text-align: center;
  min-width: 210px;
  max-width: 310px;
}

#Team_name {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
}

.Team_about {
  margin: 20px 0;
}

.Team_email {
  width: 60%;
  padding: 0.35rem 1rem;
  margin: auto;
  background-color: rgba(76, 102, 170, 0.6862745098);
  border-radius: 0.7rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Location/Team.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAIA;EACI,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AADJ;;AAKA;EACI,cAAA;AAFJ;;AAKA;EACI,UAAA;EACA,qBAAA;EACA,YAAA;EACA,kDAAA;EACA,qBAAA;AAFJ","sourcesContent":["#Team_main_1 {\n    text-align: center;\n    min-width: 210px;\n    max-width: 310px;\n\n}\n\n\n#Team_name {\n    text-transform: uppercase;\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 24px;\n    margin-top: 20px;\n}\n\n\n.Team_about {\n    margin: 20px 0;\n}\n\n.Team_email {\n    width: 60%;\n    padding: 0.35rem 1rem;\n    margin: auto;\n    background-color: #4c66aaaf;\n    border-radius: 0.7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
