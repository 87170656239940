import {  useEffect } from 'react'
import './App.css'
import AOS from 'aos';
import "aos/dist/aos.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './utils/Layout';
import { Location, HomePage, News, Solutions, About, Error, Hsck, Piolink, Nile, Secuwiz, Genians, UTM } from './pages/index'
import ScrollToTop from './hooks/useScrollToTop';


function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <Layout>
            <HomePage />
          </Layout>
        } />
        <Route path="/Location" element={
          <Layout>
            <Location />
          </Layout>
        } />
        <Route path="/about" element={
          <Layout>
            <About />
          </Layout>
        } />
        <Route path="/Solutions" element={
          <Layout>
            <Solutions />
          </Layout>
        } />
          <Route path="/Solutions/Hsck" element={
          <Layout>
            <Hsck/>
          </Layout>
        } />
          <Route path="/Solutions/Piolink" element={
          <Layout>
            <Piolink/>
          </Layout>
        } />
          <Route path="/Solutions/Secuwiz" element={
          <Layout>
            <Secuwiz/>
          </Layout>
        } />
          <Route path="/Solutions/Nile" element={
          <Layout>
            <Nile/>
          </Layout>
        } />
        <Route path="/Solutions/Genians" element={
          <Layout>
            <Genians />
          </Layout>
        } />
         <Route path="/Solutions/UTM" element={
          <Layout>
            <UTM />
          </Layout>
        } />
        <Route path="/news" element={
          <Layout>
            <News/>
          </Layout>
         } />
        <Route path="*" element={
          <Layout>
            <Error />
          </Layout>
        } />
      </Routes>
    </Router>

  )
}

export default App