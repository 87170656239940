import './HomePage.scss'
import Hmain from '../../components/HomePage/Hmain';



const HomePage = () => {
  document.title = 'SMPeoples | Home';
  return (
    <div className='pt-16 bg-black'>
      <div className='justify-center flex items-center my-[5rem] lg:gap-1 md:gap-2 gap-6 flex-col text-5xl font-bold'>
       <br/>
        <h1 data-aos="zoom-in" className='font-extrabold text-center text-transparent lg:text-7xl md:text-6xl text-5xl mx-7 bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>정보보호 전문기업</h1>
        <h1 data-aos="zoom-in" className='font-bold text-center text-transparent lg:text-4x2 md:text-4xl text-3x2 mx-4 bg-clip-text bg-gradient-to-b from-gray-300 to-gray-600 my-3'>Security Managing Peoples</h1>
        <h1 data-aos="zoom-in" className='font-extrabold text-center text-transparent lg:text-7xl md:text-5xl text-4xl bg-clip-text bg-gradient-to-r from-blue-400 to-sky-600'>에스엠피플스㈜</h1>
        <div data-aos="fade-up" className='blob absolute'>
        </div>
        <br/>
        <img data-aos="fade-up" src="./images/arc.png" className='h-[12rem] lg:my-16 md:my-10 my-0' alt="electric" />
      </div>

  

      <div className='lg:mx-[4rem] md:mx-[3rem] mx-[2rem]'>
        <Hmain/>
      </div>
    </div>
  )
}

export default HomePage
